<template>
  <section class="lista">
    <!-- Titulo -->
    <div class="topo">
      <div class="total">
        <Svgs nome="usuarios" />
        <h3>{{ storeProdutores?.produtores?.length }} produtores Level</h3>
      </div>
      <div class="input">
        <Svgs class="icone" nome="lupa" />
        <input type="text" spellcheck="false" placeholder="Buscar produtor" v-model="termoBusca" @input="filtrarProdutores" />
      </div>
    </div>
    <!-- Itens -->
    <div class="item" v-for="produtor in produtoresFiltrados" :key="produtor._id">
      <div class="info">
        <div class="foto" :style="`background-image: url('${produtor?.imagemPerfil || 'https://arquivos.unicpages.com/imagens/app/icones/placeholder-perfil.svg'}')`"></div>
        <div class="nome">
          <h3>{{ produtor?.nome }}</h3>
          <p>{{ produtor?.email }}</p>
          <div class="plano">
            <img :src="`https://arquivos.levelmember.com/imagens/planos/${produtor?.assinatura?.plano || 'free'}-ico.svg`" />
            <div class="estado" :class="{ ativo: produtor?.assinatura?.ativo }">
              <p>{{ produtor?.assinatura?.ativo ? 'Ativo' : 'Bloqueado' }}</p>
            </div>
            <h4>{{ produtor?.assinatura?.frequencia }} {{ produtor?.assinatura?.plano }}</h4>
          </div>
        </div>
      </div>
      <div class="botoes">
        <button class="sites" @click="abrirAreas(produtor)">
          <Svgs nome="cursor" />
          <p>Ver Áreas</p>
        </button>
        <button class="acesso" @click="abrirModalAcesso(produtor)">
          <Svgs nome="estrela" />
          <p>Liberar Acesso</p>
        </button>
        <button class="bloquear" @click="abrirBloquearAcesso(produtor)">
          <Svgs nome="x" />
          <p>Bloquear Acesso</p>
        </button>
        <button class="senha" @click="abrirResetarSenha(produtor)">
          <Svgs nome="cadeado" />
          <p>Resetar Senha</p>
        </button>
      </div>
    </div>
  </section>
</template>

<script setup>
import { watchEffect, onMounted, ref } from 'vue'
import { useStoreProdutores, useStoreAreas } from '@stores'
import Svgs from '@svgs'

const storeProdutores = useStoreProdutores()
const storeAreas = useStoreAreas()
const termoBusca = ref('')
const produtoresFiltrados = ref([])

function abrirAreas(produtor) {
  enviarDadosProsModals(produtor)

  const idProdutor = storeProdutores.modals.idProdutor
  storeProdutores.areasProdutor = []
  storeAreas.receberPorProdutor(idProdutor)

  storeProdutores.mostrarModalSites = true
}

function abrirModalAcesso(produtor) {
  enviarDadosProsModals(produtor)
  storeProdutores.mostrarModalAcesso = true
}

function abrirBloquearAcesso(produtor) {
  enviarDadosProsModals(produtor)
  storeProdutores.mostrarModalBloquearAcesso = true
}

function abrirResetarSenha(produtor) {
  enviarDadosProsModals(produtor)
  storeProdutores.mostrarModalResetarSenha = true
}

function enviarDadosProsModals(produtor) {
  storeProdutores.modals.idProdutor = produtor._id
  storeProdutores.modals.email = produtor.email
  storeProdutores.modals.imagemPerfil = produtor.imagemPerfil
}

function filtrarProdutores() {
  const termo = termoBusca.value.trim().toLowerCase()
  if (termo === '') {
    produtoresFiltrados.value = storeProdutores.produtores
  } else {
    produtoresFiltrados.value = storeProdutores.produtores.filter((produtor) => {
      return produtor.nome.toLowerCase().includes(termo) || produtor.email.toLowerCase().includes(termo) || produtor.assinatura.plano.toLowerCase().includes(termo) || produtor.assinatura.frequencia.toLowerCase().includes(termo)
    })
  }
}

function carregarProdutores() {
  storeProdutores.receberTodos()
}

watchEffect(() => {
  produtoresFiltrados.value = storeProdutores.produtores
})

onMounted(() => {
  carregarProdutores()
})
</script>

<style scoped>
section.lista {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 20px 20px 0 20px;
  min-height: 100dvh;
}

.topo {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 20px 0;
}

.topo .total {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 200px;
  background-color: var(--cor-cinza-2);
  border-radius: 10px;
  margin: 0 15px 0 0;
}

.topo .total svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco);
  margin: 0 10px 0 0;
}

.topo .total h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(50% - 8px);
  background-color: var(--cor-cinza-2);
  border-radius: 10px;
  padding: 20px;
  margin: 0 0 20px 0;
  transition: all 0.3s;
}

.item .info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.item .foto {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
}

.item .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: auto;
  padding: 0 0 0 15px;
}

.item .nome h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.item .nome .plano {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0 0 0;
}

.item .nome .plano h4 {
  font-family: var(--light);
  font-size: var(--f1);
  color: var(--cor-branco);
  text-transform: capitalize;
}

.item .nome .plano img {
  max-height: 12px;
  max-height: 12px;
}

.item .nome .plano .estado {
  padding: 2px 4px;
  border-radius: 50px;
  background-color: #f53a3a34;
  margin: 0 5px;
}

.item .nome .plano .estado.ativo {
  background-color: #22bc5832;
}

.item .nome .plano .estado.ativo p {
  color: var(--cor-verde);
}

.item .nome .plano .estado p {
  font-size: var(--f0);
  font-family: var(--bold);
  color: var(--cor-vermelho);
  margin: 0;
  line-height: 1;
}

.item .nome p {
  font-family: var(--light);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  line-height: 1.5;
}

.item .botoes {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.item .botoes button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-cinza-3);
  border-radius: 10px;
  margin: 5px 0 5px 10px;
  padding: 10px 15px;
  transition: all 0.3s;
}
.item .botoes button:hover {
  background-color: var(--cor-cinza-4);
}
.item .botoes button:hover p {
  color: var(--cor-branco);
}
.item .botoes button:hover svg {
  fill: var(--cor-branco);
}
.item .botoes button p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  transition: all 0.3s;
}
.item .botoes button svg {
  width: 12px;
  min-width: 12px;
  max-height: 12px;
  fill: var(--cor-cinza-5);
  margin: 0 7px 0 0;
  transition: all 0.3s;
}
/* Responsivo */
@media screen and (max-width: 1000px) {
  section.lista {
    flex-direction: column;
  }

  .topo {
    flex-direction: column;
  }

  .topo .total {
    max-width: 100%;
    padding: 15px;
    margin: 0 0 20px 0;
  }

  .item {
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    padding: 20px;
  }

  .item .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .item .botoes {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    padding: 10px 0 0 0;
  }
  .item .botoes button {
    margin: 10px 10px 0 0;
  }
}
</style>
